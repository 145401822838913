import { ClientesService } from '../services/clientes.service';
import { IClienteFormDetails } from '../types/clientes.type';

export class ClientRegulaciones implements IClienteFormDetails {
  semana_liquidacion: number | null; // POR DEFAULT ES 1
  dia_liquidacion: number | null;
  qr_bind_enable: boolean | null;
  terminal_ecr: boolean | null; // En caso de habilitarse un POS
  checkoutInternacional: boolean | null;
  iibb_misiones: boolean; // Ingresos Brutos === IIBB

  // SIRTAC
  iibb_sirtac: boolean;
  alicuota_sirtac: number | null;

  /**
   * RETENCION DE IVA -
   * SI EL VALOR ES TRUE, SE PERMITIRA EL VALOR EN alicuota_ret_iva && alicuota_ret_iva_credito
   * CASO CONTRARIO, alicuota_ret_iva && alicuota_ret_iva_credito debe ir null
   * @param data
   */
  agente_ret_iva: boolean; // Si es un agente que genera retencion de iva
  ret_iva: boolean;
  alicuota_ret_iva: number | null;
  alicuota_ret_iva_credito: number | null;

  /**
   * RETENCION DE GANANCIAS -
   * SI EL VALOR ES TRUE, SE PERMITIRA EL VALOR EN
   * _alicuota_ret_ganancia && _alicuota_ret_ganancia_credito
   * CASO CONTRARIO los campos deberan de ir null
   */
  ret_ganancia: boolean;
  alicuota_ret_ganancia: number | null;
  alicuota_ret_ganancia_credito: number | null;

  id_cliente: number | null;

  /**
   * @constructor
   * @param data
   */
  constructor(data: IClienteFormDetails | null = null) {
    this.semana_liquidacion = (data && data.semana_liquidacion) ?? 0;
    this.dia_liquidacion = (data && data.dia_liquidacion) ?? null;
    this.terminal_ecr = (data && data.terminal_ecr) || false;
    this.iibb_misiones = (data && data.iibb_misiones) ?? false;
    this.qr_bind_enable = (data && data.qr_bind_enable) ?? true;
    this.checkoutInternacional = (data && data.checkoutInternacional) ?? false;
    // RETENCION DE IVA
    this.ret_iva = (data && data.ret_iva) ?? false;
    this.alicuota_ret_iva = this.reviewDatIfTrue(
      this.ret_iva,
      (data && parseInt(data.alicuota_ret_iva?.toString().replace(/[$,.]/g, '')) ) ?? 0
    );
    this.alicuota_ret_iva_credito = this.reviewDatIfTrue(
      this.ret_iva,
      (data && parseInt(data.alicuota_ret_iva_credito?.toString().replace(/[$,.]/g, ''))) ?? 0
    );

    this.agente_ret_iva = (data && data.agente_ret_iva) ?? false;

    // RETENCION DE GANANCIAS
    this.ret_ganancia = (data && data.ret_ganancia) ?? false;
    this.alicuota_ret_ganancia = this.reviewDatIfTrue(
      this.ret_ganancia,
      (data &&  parseInt(data.alicuota_ret_ganancia?.toString().replace(/[$,.]/g, ''))) ?? 0
    );

    this.alicuota_ret_ganancia_credito = this.reviewDatIfTrue(
      this.ret_ganancia,
      (data && parseInt(data.alicuota_ret_ganancia_credito?.toString().replace(/[$,.]/g, ''))) ?? 0
    );

    // SIRTAC
    this.iibb_sirtac = (data && data.iibb_sirtac) ?? false;

    this.alicuota_sirtac = this.reviewDatIfTrue(
      this.iibb_sirtac,
      (data && parseInt(data.alicuota_sirtac?.toString().replace(/[$,.]/g, ''))) ?? 0
    );

    this.id_cliente = (data && data.id_cliente);

    //
  }

  get alicuota_ret_iva_formateada(){
    return this.formatearNumero(this.alicuota_ret_iva/100);
  }

  get alicuota_ret_iva_credito_formateada(){
    return this.formatearNumero(this.alicuota_ret_iva_credito/100);
  }

  get alicuota_ret_ganancia_formateada(){
    return this.formatearNumero(this.alicuota_ret_ganancia/100);
  }

  get alicuota_ret_ganancia_credito_formateada(){
    return this.formatearNumero(this.alicuota_ret_ganancia_credito/100);
  }

  get alicuota_sirtac_formateada(){
    return this.formatearNumero(this.alicuota_sirtac/100);
  }

  /**
   * Si la primera propiedad pasada es true, pasara un array con los datos.
   * Caso contrario, devolvera un array con null's
   */
  private reviewDatIfTrue<Type>(
    boolValue: boolean,
    value: Type
  ): Type | number {
    return boolValue ? value : 0;
  }

  currencyFormat(monto) {
    const formattedAmount = monto.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.');
    return formattedAmount;
  }
  formatearNumero(numero) {
    return numero.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}
