import { CurrencyPipe } from '@angular/common';
import { ClientesService } from '../services/clientes.service';
import { IClientesExpanded } from '../types/clientes.type';

export class Cliente implements IClientesExpanded {
  web: string;
  email: string;
  domicilio: string;
  localidad: string;
  razon_social: string;
  nombre_legal: string;
  enabled: boolean;
  documento: any;
  username: string;
  password: string;
  semana_liquidacion: number;
  dia_liquidacion:number;
  checkoutInternacional:boolean;
  terminal_ecr: boolean;
  qr_bind_enable:boolean;
  crc_sirtac: number;
  jurisdiccion_sirtac: boolean;
  iibb_misiones: boolean;
  iibb_sirtac: boolean;
  ret_iva: boolean;
  ret_ganancia: boolean;
  agente_ret_iva: boolean;
  alicuota_sirtac: number;
  alicuota_ret_iva: number;
  alicuota_ret_iva_credito: number;
  alicuota_ret_ganancia: number;
  alicuota_ret_ganancia_credito: number;
  id_cliente: number;

  constructor(cliente: IClientesExpanded | null) {
    this.web = (cliente && cliente.web) ?? '-';
    this.email = (cliente && cliente.email) ?? '-';
    this.domicilio = (cliente && cliente.domicilio) ?? '-';
    this.localidad = (cliente && cliente.localidad) ?? '-';
    this.razon_social = (cliente && cliente.razon_social) ?? '';
    this.nombre_legal = (cliente && cliente.nombre_legal) ?? '';
    this.enabled = (cliente && cliente.enabled) ?? true;
    this.documento = (cliente && cliente.documento) ?? '';
    this.semana_liquidacion = (cliente && cliente.semana_liquidacion) ?? 0;
    this.dia_liquidacion = (cliente && cliente.dia_liquidacion) ?? null;
    this.qr_bind_enable = (cliente && cliente.qr_bind_enable) ?? true;
    this.checkoutInternacional = (cliente && cliente.checkoutInternacional) ?? false;
    this.terminal_ecr = cliente && cliente.terminal_ecr;
    this.iibb_misiones = (cliente && cliente.iibb_misiones) ?? false;
    this.iibb_sirtac = (cliente && cliente.iibb_sirtac) ?? false;
    this.ret_iva = cliente && cliente.ret_iva;
    this.ret_ganancia = (cliente && cliente.ret_ganancia) ?? false; // default value
    this.agente_ret_iva = (cliente && cliente.agente_ret_iva ) ?? false;
    this.alicuota_sirtac = (cliente && cliente.alicuota_sirtac) ?? 0;
    this.alicuota_ret_iva = (cliente && cliente.alicuota_ret_iva) ?? 0;
    this.alicuota_ret_iva_credito =
      (cliente && cliente.alicuota_ret_iva_credito) ?? null;
    this.alicuota_ret_ganancia =
      (cliente && cliente.alicuota_ret_ganancia) ?? null;
    this.alicuota_ret_ganancia_credito =
      (cliente && cliente.alicuota_ret_ganancia_credito) ?? null;
    this.id_cliente = (cliente && cliente.id_cliente);

  }

  

  setAlicuotasToDouble() {
    this.alicuota_ret_ganancia = this.alicuota_ret_ganancia;
    this.alicuota_ret_ganancia_credito = this.alicuota_ret_ganancia_credito;
    this.alicuota_ret_iva = this.alicuota_ret_iva;
    this.alicuota_ret_iva_credito = this.alicuota_ret_iva_credito;
    this.alicuota_sirtac = this.alicuota_sirtac;
  }
}
