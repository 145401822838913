export const environment = {
  production: false,
  endpoint: 'https://backend.helipagos.com',
  endpointLogin: 'https://backend.helipagos.com',
 // endpoint: "https://sandbox-be.helipagos.com",
  //endpointLogin: "https://sandbox-be.helipagos.com",
  version: '1.26.0-sanpshot',

 
};
