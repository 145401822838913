import { ListResponse } from 'src/app/models/http';

export interface ideable {
  id: number;
}

/**
 * ICliente, datos minimos obtenidos del cliente en todas las solicitudes relacionadas
 * Usado comunmente en los pedidos de lista API, donde se obtienen varios clientes
 *
 *  TODO: extensiones
 */
export interface ICliente {
  razon_social: string;
  nombre_legal: string;
  enabled: boolean;
}

/**
 * Cliente Detalle - Compuesto por todos los datos visibles de un cliente
 */
export interface IClientesExpanded
  extends ICliente,
    IClientPersonalData,
    // IUserPersonalData,
    ClientExtraData,
    IClienteFormDetails {}

/**
 * ClienteResponse - Cliente junto al esquema de paginacion traido desde el backend
 */
export interface IClientesResponse extends Omit<ListResponse, 'content'> {
  content: ICliente[];
}

export interface IClientPersonalData extends Omit<ICliente, 'id' & 'enabled'> {
  // Datos personales
  web: string;
  email: string;
  domicilio: string;
  localidad: string;
  documento: number | any;
}

/**
 * Datos relacionados al usuario del cliente para el dash cliente helipagos
 */
export interface IUserPersonalData {
  username: string;
  password: string;
}

/**
 * Datos que son generados al momento de la creacion del cliente dentro de la API
 * Estos datos no se deben mandar al momento de la creacion de cliente
 */
export interface ClientExtraData {
  apikey?: string;
  roles?: string[];
  cvu_cliente?: number | string[]; // TODO: Infer better type
  cliente_cbu?: number[];
  create_at?: string;
  updated_at?: string;
}

/**
 * Datos mas relacionados al cliente de manera financiera
 */
export interface IClienteFormDetails {
  semana_liquidacion: number;
  dia_liquidacion: number;
  terminal_ecr: boolean;
  qr_bind_enable: boolean;
  checkoutInternacional: boolean;
  // IIBB - Ingresos brutos
  iibb_misiones: boolean;
  iibb_sirtac: boolean;

  // Retenciones
  ret_iva: boolean;
  ret_ganancia: boolean;
  agente_ret_iva: boolean;

  // Alicuota - Solamente aparecen cuando la retencion asociada esta activada
  alicuota_sirtac: number;
  alicuota_ret_iva: number;
  alicuota_ret_iva_credito: number;
  alicuota_ret_ganancia: number;
  alicuota_ret_ganancia_credito: number;

  id_cliente: number;
}

/**
 * Credenciales del cliente
 */
export interface ClientCredentials {
  Bearer: string; //UUID LIKe
  Webhook_key: string;
}
