import { Injectable } from '@angular/core';
import { Transaccion } from '../models/movimientos/transaccion.models';
import { SolicitudPago } from '../models/solicitud_pago.models';
import { FunctionsService } from './functions.service';
import { BaseHttpService } from '../core/base-http.service';
import { HttpClient } from '@angular/common/http';
import { Cliente } from '../dashboard/movimientos/consulta/consulta.component';
import IEstadoTransacciones from '../models/movimientos/estado-transacciones';
// import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TransaccionesService extends BaseHttpService {



  private respuestaSolicitudPago: SolicitudPago[] = [];

  private busquedaTransaccion: any;

  private idDetalle: number;
  // private requestList: Transaccion[];
  private requestList: any;

  private jsonTransmision: any[]
  private pageNumber:number;

  constructor(
    private _functionsService: FunctionsService,
    private _http: HttpClient
  ) {
    super();
   }


  setPageNumber(page: number){
    //Guarda en el servicio y en el localStorage
      this.pageNumber = page;
      localStorage.setItem("pageMovimientos", page.toString());
  }

  getPageNumber(){
    //Devuelve del servicio, caso contrario del localStorage
    if(this.pageNumber != null) return this.pageNumber;
    var page = localStorage.getItem('pageMovimientos') != null ? localStorage.getItem('pageMovimientos'): 1
    return page;
  }

  getHeadersMovimientos(){
    var headers = {
      columns_show:  ['Número', 'Estado', 'Cliente', 'Monto', 'Medio de pago', 'Fecha de importe'],
      columns_search:  ["id", "estado", "cliente", "importe", "medio_pago", "fecha_importe"],
    }
    return headers;
  }
  //getters

  getRespuestaSolicitudPago(){
    return this.respuestaSolicitudPago;
  }

  getIdDetalle(){
    return this.idDetalle != null? this.idDetalle:localStorage.getItem("idxTransaccion");
  }

  //Devuelve la lista completa
  getRequestList(){
    return this.requestList != null? this.requestList: JSON.parse(localStorage.getItem("requestList"));
  }
  //Devuelve un item del listado
  getItemRequestList(id: number){

    if(this.requestList == undefined) this.requestList =  JSON.parse(localStorage.getItem("requestList"));
     //console.log("this.requestList", this.requestList);

    var result = this.requestList.content.filter(obj => {
      return obj.id === id
    })
    return result;
  }
  getFiltrosBusqueda(){
    return this.busquedaTransaccion;
  }

  getClienteConsulta(){
    return localStorage.getItem('idxConsulta');
  }

  getSolicitudPago(id: number): SolicitudPago[]{
    this._functionsService.imprimirMensaje(this.respuestaSolicitudPago, "-- RESPUESTA SOLICITUD PAGO: ");
    return this.respuestaSolicitudPago.filter(it =>{
      return it.id == id;
    });
  }

  getJsonTransmision(){
    return this.jsonTransmision;
  }
  getListaEstadosNew(){
    return    [
                {id:1, name:"Generada"},
                {id:2, name:"Procesada"},
                {id:3, name:"Devuelta"},
                {id:4, name:"Vencida"},
                {id:5, name:"Acreditada"},
                {id:6, name:"Rechazada"},
                {id:7, name:"Conciliado"},
                {id:8, name:"Contracargo"}
              ];
  }

  getListaEstados(){
    https://sandbox-be.helipagos.com/api/medio_pago/medios_pagos
    return this._http.get<IEstadoTransacciones[]>(
      this.getUri({},'medio_pago','estados')
    )
  }

  //setters

  setRespuestaSolicitudPago(response){
    response.forEach(element => {
      var solicitud: SolicitudPago = element;
      this.respuestaSolicitudPago.push(solicitud);
    });
  }

  setFiltrosBusqueda(busqueda){
     //Guarda en el servicio
     this.busquedaTransaccion = busqueda;
     // y en el localstorage
     localStorage.setItem('params', JSON.stringify(busqueda));
  }

  setRequestList(listado:any){
    //guarda el listado
    this.requestList = listado;
    localStorage.setItem("requestList", JSON.stringify(listado));
  }
  setIdDetalle(idx: number){
    //guarda el id
    this.idDetalle = idx;
    localStorage.setItem("idxTransaccion",idx.toString());
  }

  setJsonTransmision(json : any){
    this.jsonTransmision = json;
  }

  setClienteConsulta(idx: number){
    localStorage.setItem("idxConsultaCliente",idx.toString());
  }

}
