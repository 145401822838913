import { ICON } from 'src/app/constants/Icons';
import { INavigationNode, INavigationSidebar } from '../types/nav-node.type';
import { NavigationHandlerService } from '../services/navigation-list-handler.service';
import {
  archivosTransmisionChildren,
  cobranzaChildren,
  conciliacionesChildren,
  liquidacionChildren,
  rendicionesChildren,
} from './children-nodes';

/**
 * Lista que sera leida para el sidebar
 * TODO: Completar la lista
 */
export const NavigationList: INavigationSidebar = [
  {
    title: 'Movimientos',
    icon: ICON.MOVIMIENTOS,
    href: NavigationHandlerService.URL('movimientos'),
    children: [],
    tooltip: '',
  },
  {
    title: 'Rendiciones',
    icon: ICON.RENDICIONES,
    href: NavigationHandlerService.URL('rendiciones'),
    children: [],
    tooltip: '',
  },
  {
    title: 'Liquidaciones',
    icon: ICON.LIQUIDACIONES,
    href: NavigationHandlerService.URL('liquidacion'),
    children: [],
    tooltip: '',
  },
  {
    title: 'Cobro presencial',
    icon: ICON.POS,
    href: NavigationHandlerService.URL('cobro-presencial'),
    children: [],
    tooltip: 'Cobro presencial'
  },
  {
    title: 'Archivo Base',
    icon: ICON.LIQUIDACIONES,
    href: NavigationHandlerService.URL('archivo_base'),
    children: [],
    tooltip: '',
  },
  {
    title: 'Archivos de transmisión',
    icon: ICON.ARCHIVOS_TRANSMISION,
    href: NavigationHandlerService.URL('archivos-transmision'),
    children: archivosTransmisionChildren,
    tooltip: '',
  },
  {
    title: 'Conciliaciones',
    icon: ICON.CONCILIACIONES,
    href: NavigationHandlerService.URL('conciliaciones'),
    children: conciliacionesChildren,
    tooltip: '',
  },
  {
    title: 'Usuarios',
    icon: ICON.USUARIOS,
    href: NavigationHandlerService.URL('usuarios'),
    children: [],
    tooltip: '',
  },
  {
    title: 'Clientes',
    icon: ICON.CLIENTES,
    href: NavigationHandlerService.URL('clientes'),
    children: [],
    tooltip: '',
  },
  {
    title: 'Reportes',
    icon: ICON.REPORTES,
    href: NavigationHandlerService.URL('reportes'),
    children: [],
    tooltip: '',
  },
  {
    title: 'Generar reporte whatsapp',
    icon: ICON.WHATSAPP,
    href: NavigationHandlerService.URL('whastsapp'),
    children: [],
    tooltip: '',
  },
  {
    title: 'Gestión de reportes',
    icon: ICON.GESTION_REPORTES,
    href: NavigationHandlerService.URL('gestion-reportes'),
    children: [],
    tooltip: '',
  },
  {
    title: 'Blacklist',
    icon: ICON.BLACKLIST,
    href: NavigationHandlerService.URL('blacklist'),
    children: [],
    tooltip: '',
  },
  /*{
    title: 'Archivos Procesados',
    icon: ICON.BLACKLIST,
    href: NavigationHandlerService.URL('archivos-procesados'),
    children: [],
    tooltip: '',
  },*/
  {
    title: 'Cobranza',
    icon: ICON.BLACKLIST,
    href: NavigationHandlerService.URL('cobranza'),
    children: cobranzaChildren,
    tooltip: '',
  },
/*  {
    title: 'Archivos de pago',
    icon: ICON.BLACKLIST,
    href: NavigationHandlerService.URL('payment-file'),
    children: [],
    tooltip: '',
  }*/
];

export const NavigationListWithBaseURL: INavigationNode[] = NavigationList.map(
  (item, index) => {
    if (item.children && item.children.length > 0) {
      return {
        ...item,
        children: item.children.map(
          (childItem) =>
            NavigationHandlerService.BASEURL(
              childItem,
              NavigationList[index].href
            ) // Esto no es lo mas optimo, pero resuelve el error de this.href
        ),
      };
    } else {
      return item;
    }
  }
);
